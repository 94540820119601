<template>
  <form role="form" @submit="onFormSubmit" name="feed-switch-form">
    
    <Select
      v-if="listFeeds.length > 0"
      :label="$t('blog.feed.switch.label')"
      :placeholder="$t('blog.feed.switch.select_placeholder')"
      :selectOptions="{
        options: listFeeds,
        getOptionLabel : option => option.name
      }"
      v-bind:value.sync="form.feed" 
      :labelAsColumn="false"
    />
    
    <button class="btn btn-primary ladda-button" data-style="zoom-in" type="submit">{{ $t('blog.feed.switch.button') }}</button>
  </form>
</template>

<style>
  form[name=feed-switch-form] {
    display: flex;
  }

  form[name=feed-switch-form] > div {
    display: flex;
    margin-bottom: 0;
    flex-grow:1;
  }

  form[name=feed-switch-form] > div > label {
    margin-right:10px;
    line-height: 36px;
    margin-bottom: 0px;
  }

  form[name=feed-switch-form] > div > div {
    flex-grow:1;
  }

  form[name=feed-switch-form] > button {
    margin-left:10px;
  }
</style>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';

import Select from '@fwk-client/components/panels/input/Select.vue';
import * as Ladda from 'ladda';

import { useFeedAdmin } from '../../composables/useFeedAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      Select
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { feeds, selectedFeed, selectFeed } = useFeedAdmin(props, context);

      const form:any = reactive({
        feed: selectedFeed ? selectedFeed.value.feed : undefined
      })
    
      var laddaSubmit:Ladda.LaddaButton|null = null;

      onMounted(() => {
        var button:HTMLButtonElement|null = document.querySelector( 'form[name=feed-switch-form] button' );
        laddaSubmit = Ladda.create(button!);
      })

      const onFormSubmit = (evt:Event) => {
        // We prevent submit of the page
        evt.preventDefault();

        laddaSubmit!.start();
        selectFeed(form.feed._id).then(() => {
          laddaSubmit!.stop();
        });
      }

      const listFeeds = computed(() => {
        return feeds.value.map((feed:any) => {
          return feed.feed;
        })
      })

      return { 
        listFeeds,
        form,
        selectedFeed,
        onFormSubmit
      }
    }
})
</script>