<template>
    <div class="row m-b-lg m-t-lg">
      <div class="col-md-6">
        <h2 class="no-margins">{{ name }}</h2>
        <p>
          <span v-if="selectedFeed">{{$t('blog.feed.info.id',{feedID: id})}}</span>
        </p>
      </div>
      <div class="col-md-6">
          <FeedSwitch v-if="feeds.length > 1"></FeedSwitch>
      </div>
    </div>
</template>

<script lang="ts"> 
import { Ref, defineComponent, PropType, computed, onMounted, onBeforeUnmount, ref, watch, reactive } from '@fwk-node-modules/vue'
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import FeedSwitch from './FeedSwitch.vue';

import { useFeedAdmin } from '../../composables/useFeedAdmin';

export default defineComponent({
    props: {
        
    },
    components: {
      FeedSwitch
    },
    setup(props, context) {
      const app = getApp();
      const $router = useRouter();
      const $store = useStore();

      const { selectedFeed, feeds } = useFeedAdmin(props, context);

      const name = computed(() => {
        if(selectedFeed) { return selectedFeed.value.feed.name; }
        return "";
      })

      const id = computed(() => {
        if(selectedFeed) { return selectedFeed.value.feed._id; }
        return "-";
      })

      return { 
        selectedFeed,
        name,
        id,
        feeds
      }
    }
})
</script>