import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';
import { authenticationTypes } from '@fwk-client/store/types';

import { useBlogAdmin } from "./useBlogAdmin";

import { roles as blogRoles } from '@igotweb-node-api/api/modules/blog/src/roles/index';
import { roles as apiRoles } from '@igotweb/core-api/src/roles';

interface FeedAdminInput {
}

export function useFeedAdmin(props:FeedAdminInput,context:any) { 
  const app = getApp();
  const store = useStore();

  const { callBlogAdmin } = useBlogAdmin(props, context);
  const hasUserRole = store.getters['authentication/' + authenticationTypes.getters.HAS_USER_ROLE];

  var isListLoading:Ref<boolean> = ref(false);

  const getListFeeds = async (targetDB?:string) => {
    var input = {
      targetDB
    }
    try {
      var response = await callBlogAdmin('/feeds/list', input);
      if(response.feeds) {
        return response.feeds
      }
      return [];
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const updateListFeeds = async () => {

    isListLoading.value = true;
    try {
      var feeds = await getListFeeds();
      store.commit('application/'+applicationTypes.mutations.SET_USER_FEEDS, feeds);
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
  }

  const getFeedFromID = (feedID:string) => {
    var feed = feeds.value.filter((feed:any) => {
      return feed.feed._id == feedID
    })
    if(feed.length == 1) { return feed[0].feed; }
    return null;
  }

  /**
   * selectFeed
   * This method update the selected feed by loading all associated details
   * @param feedID 
   */
  const selectFeed = async (feedID:string) => {

    // We need to load the feed and update the store
    try {
      var response = await callBlogAdmin('/feed/'+feedID);
      store.commit('application/'+applicationTypes.mutations.SET_USER_SELECTED_FEED, response.feed ? response.feed : undefined);
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const feeds = computed(() => {
    return store.getters['application/'+applicationTypes.getters.GET_USER_FEEDS];
  })

  const selectedFeed = computed(() => {
    return store.getters['application/'+applicationTypes.getters.GET_USER_SELECTED_FEED];
  })

  const feedForm:any = reactive({
    feedID: undefined,
    name: '',
    description: ''
  })

  const isFeedUpdateAllowed = (authRoles:string[]) => {
    return authRoles.indexOf(blogRoles.blog_admin) > -1
  }

  const isFeedCreateAllowed = (authRoles:string[]) => {
    return hasUserRole(apiRoles.superadmin);
  }

  const isFeedRemoveAllowed = (authRoles:string[]) => {
    return authRoles.indexOf(blogRoles.blog_admin) > -1
  }

  const createFeed = async () => {
    var input = {
      ...feedForm
    }

    try {
      var response = await callBlogAdmin('/feeds/create', input);
      if(response.created) {  
        // We update the list of feeds
        updateListFeeds();

        // We reset the form
        feedForm.name = "";
        feedForm.description = "";
        return true;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return false;
  }

  const updateFeed = async () => {

    var result:any = {
      updated:false
    }

    if(!feedForm.feedID) {
      return result;
    }

    var input = {
      ...feedForm
    }

    isListLoading.value = true;

    try {
      var response = await callBlogAdmin('/feed/'+feedForm.feedID+'/update', input);
      if(response.updated) {  
        // We update the list of feeds
        updateListFeeds();

        var feed = response.feed;
        // We update the form based on values returned by backend (in case not updated)
        feedForm.name = feed.name;
        feedForm.shortDescription = feed.shortDescription;

        result.updated = true;
        result.feed = feed;
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return result;
  }

  const removeFeed = async (feedID:string) => {

    isListLoading.value = true;

    try {
      var response = await callBlogAdmin('/feed/'+feedID+'/remove');
      if(response.removed) {
        updateListFeeds();
      }
    }
    catch(error:any) {
      console.log(error);
    }
    isListLoading.value = false;
    return true;
  }

  const connectFacebook = async (feedID:string) => {
    try {
      var response = await callBlogAdmin('/feed/'+feedID+'/connect-facebook');
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const disconnectFacebook = async (feedID:string) => {
    
    var result:any = {
      disconnected:false
    }

    try {
      var response = await callBlogAdmin('/feed/'+feedID+'/disconnect-facebook');
      if(response.disconnected) {  
        // We update the list of feeds
        updateListFeeds();

        var feed = response.feed;
        result.disconnected = true;
        result.feed = feed;
      }
    }
    catch(error:any) {
      console.log(error);
    }

    return result;
  }

  const connectLinkedIn = async (feedID:string) => {
    try {
      var response = await callBlogAdmin('/feed/'+feedID+'/connect-linkedin');
    }
    catch(error:any) {
      console.log(error);
    }
  }

  const disconnectLinkedIn = async (feedID:string) => {
    var result:any = {
      disconnected:false
    }

    try {
      var response = await callBlogAdmin('/feed/'+feedID+'/disconnect-linkedin');
      if(response.disconnected) {  
        // We update the list of feeds
        updateListFeeds();

        var feed = response.feed;
        result.disconnected = true;
        result.feed = feed;
      }
    }
    catch(error:any) {
      console.log(error);
    }

    return result;
  }

  const publishFeedProduction = async (includePosts:boolean) => {
    var result:any = {
      published: false
    }

    var input = {
      includePosts
    }

    try {
      var response = await callBlogAdmin('/feed/'+selectedFeed.value.feed._id+'/deployment/publish', input);
      if(response.published) {  
        result.published = true;
        result.feed = response.feed;
        result.posts = response.posts;

        selectFeed(selectedFeed.value.feed._id);
      }
    }
    catch(error:any) {
      console.log(error);
    }
    return result;
  }


  return {
    feeds,
    getListFeeds,
    isListLoading,
    getFeedFromID,
    selectedFeed,
    selectFeed,
    feedForm,
    isFeedUpdateAllowed,
    isFeedCreateAllowed,
    isFeedRemoveAllowed,
    removeFeed,
    createFeed,
    updateFeed,
    connectFacebook,
    disconnectFacebook,
    connectLinkedIn,
    disconnectLinkedIn,
    blogRoles,
    publishFeedProduction
  }
  
}