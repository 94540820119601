<template>
  <validation-provider ref="validator" :name="id" :mode="validationMode" :skipIfEmpty="false" :rules="computedValidationRules" v-slot="{ errors, classes }" slim>
    <div :class="{...rowCSSClass, ...classes}">
      <label v-if="inputLabel != ''" :for="id" :class="{...labelCSSClass}">{{ inputLabel }} <small v-if="required">*</small></label>
      <div :class="{...fieldCSSClass}">
        <div :class="{'input-group' : ($slots.buttons != undefined)}">
          <div v-if="selectOptions.options == null" class="sk-spinner sk-spinner sk-spinner-three-bounce">
            <div class="sk-bounce1"></div>
            <div class="sk-bounce2"></div>
            <div class="sk-bounce3"></div>
          </div>
          <v-select 
            v-if="selectOptions.options != null"
            :options="selectOptions.options" 
            :reduce="selectOptions.reduce" 
            :label="selectOptions.label" 
            :getOptionLabel="selectOptions.getOptionLabel" 
            :id="id" 
            :multiple="selectOptions.multiple" 
            :class="{...inputCSSClass, 'required':required, ...classes}" 
            :placeholder="inputPlaceholder" 
            @keypress.enter.native.prevent="" 
            ref="theSelect"
            v-model="input" >
              <template v-if="required" #search="{attributes, events}">
                <input
                  :class="{'vs__search':true}"
                  :required="!input"
                  v-bind="attributes"
                  v-on="events"
                />
              </template>
          </v-select>
          <span class="input-group-append" v-if="$slots.buttons && selectOptions.options != null"> 
            <slot name="buttons"></slot>
          </span>
        </div>
        <span :class="{...controlCSSClass}" v-if="errors.length > 0">{{ errors[0] }}</span>
      </div>
    </div>
  </validation-provider>
</template>

<script lang="ts">
import { Component, Watch, Prop } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import GenericInput from '../../mixins/GenericInput.vue';

interface SelectOptions {
  options:any[]|null,
  reduce?:any,
  label?:string,
  getOptionLabel?:any,
  multiple?:boolean,
  compareItem?:any
}

@Component({
  components : {}
})
export default class Select extends mixins<GenericInput<string>>(GenericInput) {

  @Prop({
    type: Object,
    required: true,
  }) readonly selectOptions!: SelectOptions

  get inputLabel() {
    return (this.label && this.label != "") ? this.label : "";
  }

  get inputPlaceholder() {
    return (this.placeholder && this.placeholder != "") ? this.placeholder : "";
  }

  get showSelect() {
    return this.hasInput || this.selectOptions.options.length > 0
  }

  get hasInput() {
    return this.input != '' && this.input != null;
  }

  mounted() {
    if(this.hasInput && this.$refs.validator) {
      // @ts-ignore
      this.$refs.validator.validate();
    }
  }

  validate() {
    // @ts-ignore
    return this.$refs.validator.validate();
  }

  updateSelectedFromInput() {
    if(this.hasInput) {
      var compareItem = (item:any) => {
        return item._id;
      };
      if(this.selectOptions && this.selectOptions.compareItem) {
        compareItem = this.selectOptions.compareItem;
      }
      // We get the value from the list of elements
      var selected = this.selectOptions.options.find((element) => {
        var elementCompareItem = compareItem(element);
        var inputCompareItem = compareItem(this.input);
        return elementCompareItem && inputCompareItem && elementCompareItem == inputCompareItem;
      });
      if(selected && this.$refs.theSelect) {
        // @ts-ignore
        this.$refs.theSelect.updateValue(selected);
      }
      else {
        this.input = null;
      }
    }
  }

  @Watch('selectOptions.options')
  onOptionsCHange(val:any, oldVal:any) {
    if(oldVal && oldVal.length == 0 && val && val.length > 0) {
      this.updateSelectedFromInput();
    }
  }

  @Watch('input')
  onInputChange(val: any) {
    // @ts-ignore
    this.$refs.validator.validate(val);
    this.$emit('update:value', val);
  }
  
}
</script>