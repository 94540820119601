import { Ref, watch, computed, ref, reactive, defineProps, PropType } from "@fwk-node-modules/vue";
import * as api from '@fwk-client/utils/api';
import { getApp, useRouter, useStore } from '@fwk-client/utils/vue-3-migration';
import { types as applicationTypes } from '@root/src/client/store/application';

interface BlogAdminInput {
}

export function useBlogAdmin(props:BlogAdminInput, context:any) { 
  const app = getApp();
  const store = useStore();

  var isListLoading:Ref<boolean> = ref(false);

  const updateInputWithTargetDB = (input:any) => {
    // We check if we have a target DB for the content
    var targetDB = store.getters['application/'+applicationTypes.getters.GET_ADMIN_TARGET_DB];
    if(targetDB) {
      input.targetDB = targetDB;
    }
  }

  const callBlogAdmin = async (path:string, input?:any, formData?:FormData, apiOptions?:api.ApiOptions) => {
    // We need to get the list of available companies for the current logged in user
    var options:api.ApiVueOptions =  {
      app: app,
      ...apiOptions
    }
    if(!input) { input = {}; }
    updateInputWithTargetDB(input);
    if(formData) {
      return api.postAPIFormData('/api/admin/blog' + path, formData, options, input)
    }
    return api.postAPI('/api/admin/blog' + path, input, options);
  }

  

  // We watch if we have site ID in URL
  watch(
    () => store.state.route.params.siteID,
    (val:any, oldVal:any) => {
      /*
      LOAD SITE
    }); 
    */       
    },
    { deep: true }
  )

  return {
    callBlogAdmin
  }
  
}